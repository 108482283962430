<style src="./organisation-inner-card.component.scss" lang="scss"></style>

<template>
  <v-card class="mb-32">
    <div class="organisation-inner-card">
      <div class="row">
        <div class="col-md-8 col-12">
          <div class="organisation-inner-card__info">
            <div
                class="organisation-inner-card__info-line d-flex">
              <table>
                <tbody>
                <tr>
                  <td class="organisation-inner-card__info-criteria">{{ locale.organizationName }}</td>
                  <td class="organisation-inner-card__info-reply">{{ organisation.title }}</td>
                </tr>
                <tr>
                  <td class="organisation-inner-card__info-criteria">{{ locale.webPage }}</td>
                  <td class="organisation-inner-card__info-reply">
                    <a :href="'http://'+organisation.url" target="_blank">{{ organisation.url }}</a>
                  </td>
                </tr>
<!--                <tr>-->
<!--                  <td class="organisation-inner-card__info-criteria">{{ locale.address }}</td>-->
<!--                  <td class="organisation-inner-card__info-reply">-->
<!--                    {{ organisation.address }}-->
<!--                  </td>-->
<!--                </tr>-->

                <tr v-for="(item,index) in organisation.contacts" :key="index">
                  <td class="organisation-inner-card__info-criteria">{{ item.name }}</td>
                  <td class="organisation-inner-card__info-reply" v-html="item.value"></td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
          <div class="organisation-inner-card__description">
            <div class="organisation-inner-card__description-criteria" >
              {{ locale.organizationDescription }}
            </div>
            <div class="organisation-inner-card__description-reply" v-html="organisation.description">
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12">
          <iframe :src="organisation.location"></iframe>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script src="./organisation-inner-card.component.ts"></script>
