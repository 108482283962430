import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class OrganisationsSandbox {
  private store = store;

  constructor() {
  }

  getOrganisations(): void {
    this.store.dispatch('getOrganisations');
  }

  getOrganisationDetails(id: string): void {
    this.store.dispatch('getOrganisationDetails',id);
  }

}
