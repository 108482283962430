import {mixins, Options, Vue} from 'vue-class-component';
import OrganisationInnerHeaderComponent
    from '@/pages/organisations-inner/components/organisation-inner-header/organisation-inner-header.component.vue';
import OrganisationInnerCardComponent
    from '@/pages/organisations-inner/components/organisation-inner-card/organisation-inner-card.component.vue';
import BoxComponent from '@/core/shared/box/box.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {IOrganisationDetails} from '@/pages/organisations/types/organisation-details';
import {OrganisationsSandbox} from '@/pages/organisations/sandbox/organisations.sandbox';
import {container} from 'tsyringe';
import choiceRouter from '@/core/helpers/routers';
import {AuthenticationSandbox} from '@/core/auth/authentication.sandbox';
import {CheckAuthService} from '@/mixin/mixin';

@Options({
    name: 'organisations-inner-component',
    components: {
        OrganisationInnerHeaderComponent,
        OrganisationInnerCardComponent,
        BoxComponent,
    }
})
export default class OrganisationsInnerComponent extends mixins(CheckAuthService) {
    public sandboxAuth: AuthenticationSandbox = container.resolve(AuthenticationSandbox);
    public locale$: any = locale;
    public locale: any = null;
    private sandbox: OrganisationsSandbox = container.resolve(OrganisationsSandbox);


    public organisationInfo: any =
        {
            name: 'Məlumat Hesablama Mərkəzi',
            icon: '1.png',
            webSite: 'https://rabita.az/az/index',
            tel: '157',
            address: 'Azərbaycan, Bakı Dilarə Əliyeva küç. 185',
            description: 'Bu xidmət telekommunikasiya vasitələri və qurğularına uyğunluq sertifikatı verilməsi üçün müraciətlərə baxılması, tələb olunan sənədlərin qəbul edilməsi və telekommunikasiya vasitələri və qurğularının qarşılıqlı telekommunikasiya şəbəkəsinə (QTŞ) uyğunluğu barədə sertifikatın verilməsinə dair xidmətlərin göstərilməsindən ibarətdir.',
            map: 'https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3039.442343695606!2d49.84315561489326!3d40.37688786598641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1zQXrJmXJiYXljYW4sIEJha8SxIERpbGFyyZkgxo9saXlldmEga8O8w6cuIDE4NQ!5e0!3m2!1sru!2s!4v1634898274875!5m2!1sru!2s'

        };
    public services: any = [
        {
            name: 'E-gov təlimlərinə elektron qeydiyyat',
            icon: 'connection.svg'
        },
        {
            name: 'E-gov təlimlərinə elektron qeydiyyat',
            icon: 'connection.svg'
        },
        {
            name: 'E-gov təlimlərinə elektron qeydiyyat',
            icon: 'connection.svg'
        },
        {
            name: 'E-gov təlimlərinə elektron qeydiyyat',
            icon: 'connection.svg'
        },
        {
            name: 'E-gov təlimlərinə elektron qeydiyyat',
            icon: 'connection.svg'
        },
        {
            name: 'E-gov təlimlərinə elektron qeydiyyat',
            icon: 'connection.svg'
        },
        {
            name: 'E-gov təlimlərinə elektron qeydiyyat',
            icon: 'connection.svg'
        },
        {
            name: 'E-gov təlimlərinə elektron qeydiyyat',
            icon: 'connection.svg'
        },
    ];

    get organisation(): IOrganisationDetails[] {
        return store.getters._getOrganisationDetails;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.organisationInner) {
                this.locale = res.organisationInner.header;
            }
        });
        let id = this.$route.params.id.toString();
        this.sandbox.getOrganisationDetails(id);
    }

    public defineService(id: number) {
        // return choiceRouter(id);
    }

    public clickLink(service: any) {
        this.checkAuthService(service);
        // if (service.authRequired && !localStorage.getItem('mainToken')) {
        //     this.sandboxAuth.getSSOToken(choiceRouter(service.id));
        // } else {
        //     this.$router.push({name: choiceRouter(service.id)});
        // }
    }
}
