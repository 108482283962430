import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "organisations-inner" }
const _hoisted_2 = { class: "container-fluid v-container mt-40" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "container-fluid v-container mt-40" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "container-fluid v-container mt-40" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12 mb-20" }
const _hoisted_11 = { class: "organisations-inner__count ml-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_organisation_inner_header_component = _resolveComponent("organisation-inner-header-component")!
  const _component_organisation_inner_card_component = _resolveComponent("organisation-inner-card-component")!
  const _component_v_title = _resolveComponent("v-title")!
  const _component_box_component = _resolveComponent("box-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_organisation_inner_header_component, {
              class: "mb-20",
              title: _ctx.organisation.title,
              icon: _ctx.organisation.icon
            }, null, 8, ["title", "icon"])
          ])
        ])
      ])
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_organisation_inner_card_component, {
              organisation: _ctx.organisation,
              class: "mb-12"
            }, null, 8, ["organisation"])
          ])
        ])
      ])
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_v_title, { class: "d-flex align-center" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.locale.services) + " ", 1),
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.organisation.serviceCount), 1)
              ]),
              _: 1
            })
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organisation?.services, (serv, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-12 col-md-4 col-lg-3 mb-20",
              key: index
            }, [
              _createVNode(_component_box_component, {
                onClick: ($event: any) => (_ctx.clickLink(serv)),
                registration: serv.authRequired,
                title: serv.title,
                icon: serv.icon,
                direction: "column",
                "title-size": "16",
                "bold-title": ""
              }, null, 8, ["onClick", "registration", "title", "icon"])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}