<style src="./organisation-inner-header.component.scss" lang="scss"></style>

<template>
  <v-card>
    <div class="organisation-inner-header d-flex align-center">
      <img :src="icon" alt="icon">
      <div class="organisation-inner-header__title ml-24">
        {{ title }}
      </div>
    </div>
  </v-card>

</template>

<script src="./organisation-inner-header.component.ts"></script>
