import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';

@Options({
    name: 'organisation-inner-card-component',
    props: {
        organisation: {
            type: Object,
            required: true,
        }, // name, webSite, tel, address, description, map
    },
})

export default class OrganisationInnerCardComponent extends Vue {
    public organisation: any = {}

    public locale$: any = locale;
    public locale: any = null;

    public created():void {
        this.locale$.subscribe((res: any) => {
            if (res && res.organisationInner) {
                this.locale = res.organisationInner.card;
            }
        });
    }

}
